import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logoutUser, setLoginUser } from "../../app-store/reducers/user/user-reducer";
import CCModal from "../../components/Modal";
import SessionExpiryModal from "../../components/Modal/SessionExpiryModal";
import { getLabel } from "../../hooks/use-labels";
import { CookieService } from "../../service/cookie-service";
import { secondsToHms } from "../../utils/modular-function";
import { refreshAuthToken } from "apis/b2c_api";

const SessionTimeoutModal = (props: any) => {
  const { isOpen, onClose, onLogoutClick } = props;
  const [time, setTime] = useState(
    Number(CookieService.getCookie("cc_session_timeout"))
  );
  const { activeUser } = useSelector((state: any) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "action") {
        if (e.newValue === "updateIdleTime") {
          handleStay();
        } else if (e.newValue === "updateLogoutAction") {
          handleLogout();
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let frameId: number;
    let intervalStartTime: number = Date.now();
    const updateCountdown = () => {
      const elapsedTime = Date.now() - intervalStartTime;
      if (elapsedTime >= 1000) {
        const secondsElapsed = Math.floor(elapsedTime / 1000);
        setTime((prev: number) => prev - secondsElapsed);
        intervalStartTime += secondsElapsed * 1000;
      }
      if (time > 0) {
        frameId = window.requestAnimationFrame(updateCountdown);
      } else {
        handleLogout();
      }
    };
    frameId = window.requestAnimationFrame(updateCountdown);
    return () => {
      window.cancelAnimationFrame(frameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleLogout = () => {
    dispatch(logoutUser());
    CookieService.removeCookie("cc_sessionTimeoutAt");
    navigate("/");
    onLogoutClick();
  };

  const handleStay = () => {
    refreshAuthToken(activeUser.refresh_token).then((response: any) => {
      const resp = response.refreshAuthToken.authToken
      const preparedReap = {
        access_token: resp.accessToken,
        refresh_token: resp.refreshToken,
      }
      dispatch(setLoginUser(preparedReap));
      CookieService.removeCookie("cc_sessionTimeoutAt");
    }).catch(console.error);
    onClose();
  };

  const modalProps = {
    title: getLabel("sessionExpiryTitle"),
    description: getLabel("sessionExpiryDescription"),
    timer: secondsToHms(time),
    onConfirm: handleStay,
    className: "session-expiry-modal",
    logOut: handleLogout,
  };

  const renderModal = (
    <CCModal open={isOpen} onClose={() => handleStay()}>
      <SessionExpiryModal {...modalProps} />
    </CCModal>
  );

  return <Fragment>{renderModal}</Fragment>;
};
export default SessionTimeoutModal;
