import { Button } from "antd";
import React, { useEffect } from "react";

export interface PrimaryButtonProps {
  type: string;
  label?: string;
  size?: string;
  disable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  htmlType?: "button" | "reset" | "submit" | undefined;
  className?: string;
  icon?: string;
  style?: React.CSSProperties;
  width?: string;
  loading?: boolean;
  btnBackgroundHoverColor?: string;
  iconPrefix?: boolean;
  svgIcon?: React.ReactNode;
}

const OutlineButtonComponent = ({
  type,
  label,
  size = "lg",
  disable = false,
  onClick,
  htmlType,
  className,
  icon,
  width,
  style,
  loading,
  svgIcon,
  btnBackgroundHoverColor,
  iconPrefix = false,
}: PrimaryButtonProps) => {
  const styles = {
    width: `${width}px`,
    ...style,
  };

  useEffect(() => {
    if (btnBackgroundHoverColor) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>
            .ant-btn-default.consumer-btn:not(:disabled):hover{
              background-color:${btnBackgroundHoverColor};
              border-color: ${btnBackgroundHoverColor};
          }
          </style>`
      );
    }
  }, [btnBackgroundHoverColor]);

  return (
    <Button
      htmlType={htmlType}
      id={label}
      className={`${className} consumer-btn button-box-shadow button-${
        size ? size : "default"
      } ${!disable ? `${type}-enabled` : `${type}-disabled`} ${
        iconPrefix ? "justify-content-around" : ""
      }`}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        !disable && onClick && onClick(event);
      }}
      style={{ ...styles }}
      loading={loading}
    >
        {svgIcon && iconPrefix && (
        <span className="outline-button-icon">{svgIcon}</span>
      )}
      {icon && iconPrefix && (
        <span className="outline-button-icon">
          <img src={icon} alt="outline-icon" />
        </span>
      )}
      {label && label}
      {icon && !iconPrefix && (
        <span className="button-icon-container">
          <img src={icon} alt="button-icon" />
        </span>
      )}
    </Button>
  );
};

export default OutlineButtonComponent;
