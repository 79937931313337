import { MutationAuthLoginArgs } from "utils/graphql";

export const prepareLoginParams = (values: MutationAuthLoginArgs) => {
  return {
    username: values.username ??'',
    password: values.password,
    deviceInfo: values.deviceInfo ?? { device_unique_id: ''},    
  }
}

export const prepareReturnResponce = (response:any) => {
  return {
    access_token: response.accessToken,
    password_expiry_remaining_days: response.passwordExpiryRemainingDays,
    refresh_token: response.refreshToken,
    
  }
}

export const resetUserDetails = () => {
  const resetKeys = ['activeUser', 'userProfile', 'Cloud_SecureURL'];
  resetKeys.forEach((key) =>
    localStorage.removeItem(key)
  )
}