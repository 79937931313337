import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { getSecureURLDetails, timeDiffFromNow } from "../utils/common-utils";
import moment from "moment";
import { resetUserDetails } from "../app-store/reducers/user/user-action";
import {
  logoutUser,
  setLoginUser,
} from "../app-store/reducers/user/user-reducer";
import { parseURL } from "../utils/modular-function";
import { refreshAuthToken } from "apis/b2c_api";

let timeoutRef: any;
const stopTokenRefresh = () => {
  if (timeoutRef) {
    clearTimeout(timeoutRef);
  }
};

const RefreshAuth = (props: any) => {
  const { activeUser } = useSelector((state: any) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeUser.access_token) {
      const { exp }: any = jwt_decode(activeUser.access_token);
      console.log("exp", exp);

      const expiryAt = moment(exp * 1000);
      console.log("expiryAt", expiryAt);

      const TIME_IN_SECONDS = timeDiffFromNow(expiryAt);
      console.log("TIME_IN_SECONDS", TIME_IN_SECONDS);

      const REFRESH_TIME = TIME_IN_SECONDS - 30;
      console.log("REFRESH_TIME", REFRESH_TIME);

      timeoutRef = setTimeout(() => {
        console.log("set time out in");
        stopTokenRefresh();
        console.log("set time out out");

        if (activeUser.refresh_token) {
          refreshAuthToken(activeUser.refresh_token)
            .then((response: any) => {
              const resp = response.refreshAuthToken.authToken;
              const preparedResponse  = {
                access_token: resp.accessToken,
                refresh_token: resp.refreshToken,
              };
              dispatch(setLoginUser(preparedResponse));
            })
            .catch((error) => {
              if (error) {
                const secureURL = getSecureURLDetails();

                if (secureURL) {
                  resetUserDetails();
                  const { callback_url } = JSON.parse(secureURL);
                  window.location.href = parseURL(callback_url);
                } else {
                  dispatch(logoutUser());
                }
              }
            });
        }
      }, REFRESH_TIME * 1000);
    } else {
      stopTokenRefresh();
    }

    return () => {
      stopTokenRefresh();
    };
  }, [activeUser.access_token, activeUser.refresh_token, dispatch]);
  return <></>;
};

export default RefreshAuth;
