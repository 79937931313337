
import { MutationAuthLoginArgs, MutationCreateAuthConsumerArgs, MutationCreateCreditTransferBatchArgs } from "utils/graphql";
import { query, mutation, loginQuery } from "../../utils/apollo";

export const fetchMetaData = () => {
  return query(`
    query metadata {
      metadata
    }
  `);
};

export const getExchangeRates = () => {
  return query(
    `query definitions ($productCode: String) {
            definitions {
                exchangeRates (productCode: $productCode){
                    rate
                    from
                    to
                }
            }
        }`
  );
};

export const getRecipientList = (Recipientprops: any) => {
  const { pageSize, pageNumber, consumerId } = Recipientprops;

  return query(
    `
    query recipientsList ($consumerId: String!, $limit: Int, $offset: Int) {
        recipientsList (consumerId: $consumerId, limit: $limit, offset: $offset) {
            edges {
                cursor
                node {
                    consumerId
                    email
                    firstName
                    lastName
                    entity {
                        id
                        name
                        type
                        ddaNumber
                        title
                    }
                    id
                    nickName
                    phoneDetails {
                        countryDialingCode
                        phoneNumber
                        type
                    }
                }
            }
            nodes {
                consumerId
                email
                firstName
                lastName
                entity {
                    id
                    name
                    type
                    ddaNumber
                    title
                }
                id
                nickName
                phoneDetails {
                    countryDialingCode
                    phoneNumber
                    type
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                limit
                offset
                startCursor
                totalPages
                totalRecords
            }
        }
    }
    `,
    {
      offset: pageNumber,
      limit: pageSize,
      ...(consumerId && { consumerId }),
    }
  );
};

export const fetchProfileData = (accountProps: any) => {
  return query(
    `
    query profile ($transType: String!, $variableCode: String) {
        profile (variableCode: $variableCode) {
            accounts {
                accountProxyNo
                accountWallets {
                    accountId
                    availableBalance
                    createdOn
                    chainingPriority
                    includedInChaining
                    currency
                    currentBalance
                    id
                    isDefaultWallet
                    productWalletCode
                    title
                }
                aggregatedAvailableBalance {
                    amount
                    currency
                }
                aggregatedCurrentBalance {
                    amount
                    currency
                }
                cards {
                    accountId
                    cardExpiry
                    consumerId
                    creationDate
                    fulfillment {
                        patternCode
                        shippingType
                    }
                    id
                    lastFour
                    paymentNetwork
                    printedConsumerName
                    productCode
                    productName
                    replacementFor
                    replacementReason
                    status
                }
                consumerId
                createdOn
                ddaNumber
                displayOrder
                id
                lastCreditOrDebit (transType: $transType) {
                    amount
                    currency
                    date
                    exchangeRate
                }
                productCode
                productName
                routingNumber
                status
                title
                type
            }
            addresses {
                address1
                address2
                addressType
                city
                country
                postalCode
                state
            }
            citizenshipCountryCode
            dateOfBirth
            email
            externalRefNo
            firstName
            gender
            entityType
            id
            kycData {
                expiryDate
                issuanceCountry
                issuanceDate
                issuanceId
                maskedIssuanceId
                type
            }
            lastName
            phoneDetails {
                countryDialingCode
                phoneNumber
                type
            }
        }
    }
  `,
    { ...accountProps }
  );
};

export const fetchFriendFamilyData = (data: any) => {
  return query(
    `
    query findConsumers ($countryCode: String, $email: String, $firstName: String, $lastName: String, $limit: Int, $offset: Int, $phoneNumber: String, $ddaNumber: String) {
        findConsumers (countryCode: $countryCode, email: $email, firstName: $firstName, lastName: $lastName, limit: $limit, offset: $offset, phoneNumber: $phoneNumber, ddaNumber: $ddaNumber) {
            edges {
                cursor
                node {
                    accounts
                    cards
                    email
                    firstName
                    id
                    lastName
                    phoneDetails {
                        countryDialingCode
                        phoneNumber
                        type
                    }
                }
            }
            nodes {
                accounts
                cards
                email
                firstName
                id
                lastName
                phoneDetails {
                    countryDialingCode
                    phoneNumber
                    type
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                limit
                offset
                startCursor
                totalPages
                totalRecords
            }
        }
    }
  `,
    { ...data }
  );
};

export const fetchBankAccounts = () => {
  return query(`
  query bankAccounts {
    bankAccounts {
        accountNumber
        accountStatus
        accountTitle
        accountType
        bankName
        consumerId
        country
        currencyCode
        id
        label
        routingNumber
        verificationStatus
    }
}
  `);
};

export const otpRequired = () => {
  return query(`
    query otpRequired {
        otpRequired
    }
    `);
};

export const fetchRecentTransfers = (Paginationprops?: any) => {
  return query(
    `query transfers ($limit: Int, $offset: Int, $payerId: String, $payerType: String, $status: String, $transferType: String) {
        transfers (limit: $limit, offset: $offset, payerId: $payerId, payerType: $payerType, status: $status, transferType: $transferType) {
            edges {
                cursor
                node {
                    beneficiary {
                        id
                        name
                        type
                    }
                    description
                    externalRefNo
                    feeAmount {
                        amount
                        currency
                        exchangeRate
                    }
                    id
                    payer {
                        id
                        name
                        type
                    }
                    status
                    transferAmount {
                        amount
                        currency
                        exchangeRate
                    }
                    transferDateTime
                    transferType
                }
            }
            nodes {
                beneficiary {
                    id
                    name
                    walletTitle
                    type
                }
                description
                externalRefNo
                feeAmount {
                    amount
                    currency
                    exchangeRate
                }
                id
                payer {
                    id
                    name
                    walletTitle
                    type
                }
                status
                transferAmount {
                    amount
                    currency
                    exchangeRate
                }
                transferDateTime
                transferType
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                limit
                offset
                startCursor
                totalPages
                totalRecords
            }
        }
    }`,
    { ...Paginationprops }
  );
};

export const createSubAccounts = (accountProps: any) => {
  return mutation(
    `mutation createAccountWallet ($accountId: ID!, $currency: String!, $title: String!) {
      createAccountWallet (accountId: $accountId, currency: $currency, title: $title) {
          accountWallet {
              accountId
              availableBalance
              createdOn
              currency
              currentBalance
              id
              productWalletCode
          }
      }
  }`,
    { ...accountProps }
  );
};

export const createRecipient = (accountProps: any) => {
  return mutation(
    `mutation createRecipient ($consumerId: String, $entityId: String, $entityType: String, $nickName: String) {
        createRecipient (consumerId: $consumerId, entityId: $entityId, entityType: $entityType, nickName: $nickName) {
            recipient {
                consumerId
                entity {
                    id
                    name
                    type
                    walletTitle
                }
                id
                nickName
            }
        }
    }`,
    { ...accountProps }
  );
};

export const payerToPayerTransfer = (accountProps: any) => {
  return mutation(
    `mutation payerToPayerTransfer ($beneficiary: EntityInput!, $description: String, $externalRefNo: String, $payer: EntityInput!, $transferAmount: TransferAmountInput!, $transferType: String) {
        payerToPayerTransfer (beneficiary: $beneficiary, description: $description, externalRefNo: $externalRefNo, payer: $payer, transferAmount: $transferAmount, transferType: $transferType) {
            transfer {
                beneficiary {
                    id
                    name
                    type
                    walletTitle
                }
                description
                externalRefNo
                feeAmount {
                    amount
                    currency
                    exchangeRate
                }
                id
                payer {
                    id
                    name
                    type
                    walletTitle
                }
                status
                transferAmount {
                    amount
                    currency
                    exchangeRate
                }
                transferDateTime
                transferType
            }
        }
    }`,
    { ...accountProps }
  );
};

export const sendOtp = (accountProps: any) => {
  return mutation(
    `mutation sendOtp ($email: String, $phoneNo: String) {
        sendOtp (email: $email, phoneNo: $phoneNo) {
            otpStatus
        }
    }`,
    { ...accountProps }
  );
};

export const verifyOtp = (accountProps: any) => {
  return mutation(
    `mutation verifyOtp ($email: String, $otpValue: String!, $phoneNo: String) {
        verifyOtp (email: $email, otpValue: $otpValue, phoneNo: $phoneNo) {
            otpStatus
        }
    }`,
    { ...accountProps }
  );
};

export const removeRecipient = (Props: any) => {
  return mutation(
    `mutation removeRecipient ($consumerId: String!, $recipientId: String!) {
        removeRecipient (consumerId: $consumerId, recipientId: $recipientId) {
            status
        }
    }`,
    { ...Props }
  );
};

export const createBankAccounts = (accountProps: any) => {
  return mutation(
    `mutation createBankAccount ($accountNumber: String!, $accountTitle: String!, $accountType: String!, $bankName: String!, $country: String, $currencyCode: String, $initiateVerification: Boolean, $isPreVerified: Boolean, $label: String, $routingNumber: String!) {
      createBankAccount (accountNumber: $accountNumber, accountTitle: $accountTitle, accountType: $accountType, bankName: $bankName, country: $country, currencyCode: $currencyCode, initiateVerification: $initiateVerification, isPreVerified: $isPreVerified, label: $label, routingNumber: $routingNumber) {
          bankAccount {
              accountNumber
              accountStatus
              accountTitle
              accountType
              bankName
              consumerId
              country
              currencyCode
              id
              label
              routingNumber
              verificationStatus
          }
      }
  }`,
    { ...accountProps }
  );
};

export const verifyBankAccount = (accountProps: any) => {
  return mutation(
    `mutation verifyBankAccount ($amount1: Float!, $amount2: Float!, $bankAccountId: ID!) {
      verifyBankAccount (amount1: $amount1, amount2: $amount2, bankAccountId: $bankAccountId) {
          bankAccount {
              accountNumber
              accountStatus
              accountTitle
              accountType
              bankName
              consumerId
              country
              currencyCode
              id
              label
              routingNumber
              verificationStatus
          }
      }
  }`,
    { ...accountProps }
  );
};

export const updateSubAccounts = (accountProps: any) => {
  return mutation(
    `mutation updateAccountWallet ($accountId: ID!, $chainingPriority: Int, $includedInChaining: Boolean, $walletId: ID!, $title: String, $isDefault: Boolean) {
      updateAccountWallet (accountId: $accountId, walletId: $walletId, title: $title, chainingPriority: $chainingPriority, includedInChaining: $includedInChaining, isDefault: $isDefault) {
          wallet {
              accountId
              availableBalance
              createdOn
              currency
              currentBalance
              id
              isDefaultWallet
              productWalletCode
              title
          }
      }
  }`,
    { ...accountProps }
  );
};

export const sendMoneyAccounts = (accountProps: any) => {
  return mutation(
    `mutation initiateTransferSend ($bankAccount: BankAccountInput!, $companyId: String, $description: String, $entity: EntityInput!, $externalRefNo: String, $individualId: String, $sameDayTransfer: Boolean, $transferAmount: TransferAmountInput!, $transferDate: String!) {
      initiateTransferSend (bankAccount: $bankAccount, companyId: $companyId, description: $description, entity: $entity, externalRefNo: $externalRefNo, individualId: $individualId, sameDayTransfer: $sameDayTransfer, transferAmount: $transferAmount, transferDate: $transferDate) {
          bankTransfer {
              accountId
              accountWalletId
              bankAccount {
                  accountNumber
                  accountStatus
                  accountTitle
                  accountType
                  bankName
                  country
                  currencyCode
                  id
                  label
                  routingNumber
                  verificationStatus
              }
              cardId
              companyId
              consumerId
              description
              externalRefNo
              feeAmount {
                  amount
                  currency
                  exchangeRate
              }
              id
              individualId
              sameDayTransfer
              status
              transferAmount {
                  amount
                  currency
                  exchangeRate
              }
              transferDate
              transferType
          }
      }
  }`,
    { ...accountProps }
  );
};

export const initiateTransferReceive = (accountProps: any) => {
  return mutation(
    `mutation initiateTransferReceive ($entity: EntityInput!, $bankAccountId: String!, $transferAmount: TransferAmountInput!, $transferDate: String!, $sameDayTransfer: Boolean, $companyId: String, $individualId: String, $description: String, $externalRefNo: String) {
      initiateTransferReceive (entity: $entity, bankAccountId: $bankAccountId, transferAmount: $transferAmount, transferDate: $transferDate, sameDayTransfer: $sameDayTransfer, companyId: $companyId, individualId: $individualId, description: $description, externalRefNo: $externalRefNo) {
          bankTransfer {
              accountId
              accountWalletId
              bankAccount {
                  accountNumber
                  accountStatus
                  accountTitle
                  accountType
                  bankName
                  country
                  currencyCode
                  id
                  label
                  routingNumber
                  verificationStatus
              }
              cardId
              companyId
              consumerId
              description
              externalRefNo
              feeAmount {
                  amount
                  currency
                  exchangeRate
              }
              id
              individualId
              sameDayTransfer
              status
              transferAmount {
                  amount
                  currency
                  exchangeRate
              }
              transferDate
              transferType
          }
      }
  }`,
    { ...accountProps }
  );
};

export const fetchTransactions = (transactionsProps: any) => {
  const {
    pageSize,
    pageNumber,
    fromDate,
    toDate,
    transactionStatus,
    mainAccount,
    walletAccount,
    financialImpact,
    merchantName,
  } = transactionsProps;

  return query(
    `
    query transactions ($accountId: ID, $accountWalletId: String, $merchantName: String, $cardId: ID, $financial: Boolean, $financialImpact: String, $fromDate: String, $limit: Int, $offset: Int, $toDate: String, $transactionStatus: String) {
      transactions (accountId: $accountId, accountWalletId: $accountWalletId, merchantName: $merchantName, cardId: $cardId, financial: $financial, financialImpact: $financialImpact, fromDate: $fromDate, limit: $limit, offset: $offset, toDate: $toDate, transactionStatus: $transactionStatus) {
          edges {
              cursor
              node {
                  accountId
                  accountWalletId
                  approvedAmount {
                      amount
                      currency
                      date
                      exchangeRate
                  }
                  authExpireOn
                  authId
                  availableBalance {
                      amount
                      currency
                  }
                  cardId
                  cardLastFour
                  cashbackAmount {
                      amount
                      currency
                      date
                      exchangeRate
                  }
                  consumerId
                  currentBalance {
                      amount
                      currency
                  }
                  description
                  externalRefNo
                  feeAmount {
                      amount
                      currency
                      date
                      exchangeRate
                  }
                  id
                  interfaceType
                  isAdvice
                  merchant {
                      city
                      country
                      group
                      name
                      postalCode
                      state
                      type
                  }
                  messageType
                  originalTransactionId
                  partialApproved
                  paymentNetwork
                  productCode
                  requestType
                  resultCode
                  resultDetailCode
                  retrievalReferenceNo
                  surchargeAmount {
                      amount
                      currency
                      date
                      exchangeRate
                  }
                  transactionAmount {
                      amount
                      currency
                      date
                      exchangeRate
                  }
                  transactionDatetime
                  transactionStatus
                  transactionType
              }
          }
          nodes {
              accountId
              accountWalletId
              approvedAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              authExpireOn
              authId
              availableBalance {
                  amount
                  currency
              }
              cardId
              cardLastFour
              cashbackAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              consumerId
              currentBalance {
                  amount
                  currency
              }
              description
              externalRefNo
              feeAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              id
              interfaceType
              isAdvice
              merchant {
                  city
                  country
                  group
                  name
                  postalCode
                  state
                  type
              }
              messageType
              originalTransactionId
              partialApproved
              paymentNetwork
              productCode
              requestType
              resultCode
              resultDetailCode
              retrievalReferenceNo
              surchargeAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              transactionAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              transactionDatetime
              transactionStatus
              transactionType
          }
          pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              limit
              offset
              startCursor
              totalPages
              totalRecords
          }
      }
  }
    `,
    {
      offset: pageNumber,
      limit: pageSize,
      ...(financialImpact && { financialImpact }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(transactionStatus && { transactionStatus }),
      ...(mainAccount && { accountId: mainAccount }),
      ...(walletAccount && { accountWalletId: walletAccount }),
      ...(merchantName && { merchantName }),
      // ...restFilters
    }
  );
};

export const fetchRecentTransactions = (transactionsProps: any) => {
  const {
    pageSize = 5,
    pageNumber = 1,
    fromDate,
    toDate,
    transactionStatus,
    walletAccount,
    financialImpact,
    merchantName,
    accountId,
  } = transactionsProps;

  return query(
    `
    query transactions ($accountId: ID, $accountWalletId: String, $merchantName: String, $cardId: ID, $financial: Boolean, $financialImpact: String, $fromDate: String, $limit: Int, $offset: Int, $toDate: String, $transactionStatus: String) {
      transactions (accountId: $accountId, accountWalletId: $accountWalletId, merchantName: $merchantName, cardId: $cardId, financial: $financial, financialImpact: $financialImpact, fromDate: $fromDate, limit: $limit, offset: $offset, toDate: $toDate, transactionStatus: $transactionStatus) {
          nodes {
            id
            accountId
            cardId
            cardLastFour
            consumerId
            description
            interfaceType
            isAdvice
            approvedAmount {
              amount
              currency
              date
              exchangeRate
            }
            merchant {
                city
                country
                group
                name
                postalCode
                state
                type
            }
            originalTransactionId
            transactionAmount {
                amount
                currency
                date
                exchangeRate
            }
            transactionDatetime
            transactionStatus
            transactionType
          }
          pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              limit
              offset
              startCursor
              totalPages
              totalRecords
          }
      }
  }
    `,
    {
      offset: pageNumber,
      limit: pageSize,
      ...(financialImpact && { financialImpact }),
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(transactionStatus && { transactionStatus }),
      ...(accountId && { accountId: accountId }),
      ...(merchantName && { merchantName }),
      ...(walletAccount && { accountWalletId: walletAccount }),
      // ...restFilters
    }
  );
};

export const fetchBankTransfer = (bankTransferProps: any) => {
  const { pageSize, pageNumber, fromDate, toDate } = bankTransferProps;

  return query(
    `
    query bankTransfers ($fromDate: String!, $limit: Int, $offset: Int, $toDate: String) {
      bankTransfers (fromDate: $fromDate, limit: $limit, offset: $offset, toDate: $toDate) {
          edges {
              cursor
              node {
                  accountId
                  accountWalletId
                  bankAccount {
                      accountNumber
                      accountStatus
                      accountTitle
                      accountType
                      bankName
                      country
                      currencyCode
                      id
                      label
                      routingNumber
                      verificationStatus
                  }
                  cardId
                  companyId
                  description
                  externalRefNo
                  feeAmount {
                      amount
                      currency
                      exchangeRate
                  }
                  id
                  individualId
                  sameDayTransfer
                  status
                  transferAmount {
                      amount
                      currency
                      exchangeRate
                  }
                  transferDate
                  transferType
              }
          }
          nodes {
              accountId
              accountWalletId
              bankAccount {
                  accountNumber
                  accountStatus
                  accountTitle
                  accountType
                  bankName
                  country
                  currencyCode
                  id
                  label
                  routingNumber
                  verificationStatus
              }
              cardId
              companyId
              description
              externalRefNo
              feeAmount {
                  amount
                  currency
                  exchangeRate
              }
              id
              individualId
              sameDayTransfer
              status
              transferAmount {
                  amount
                  currency
                  exchangeRate
              }
              transferDate
              transferType
          }
          pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              limit
              offset
              startCursor
              totalPages
              totalRecords
          }
      }
  }
    `,
    {
      offset: pageNumber,
      limit: pageSize,
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
    }
  );
};

export const fetchAccountWalletStats = (balanceProps: any) => {
  const { accountWalletId, dateTo, dateFrom, interval } = balanceProps;

  return query(
    `
        query accountWalletStats ($accountWalletId: String!, $dateFrom: String!, $dateTo: String!, $interval: String!) {
            accountWalletStats (accountWalletId: $accountWalletId, dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval) {
                activityDate
                availableBalance
                currentBalance
            }
        }
    `,
    {
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...(interval && { interval }),
      ...(accountWalletId && { accountWalletId }),
    }
  );
};

export const fetchSpending = (transactionsProps: any) => {
  const {
    pageSize,
    pageNumber,
    fromDate,
    toDate,
    transactionStatus,
    accountId,
    walletAccount,
  } = transactionsProps;

  return query(
    `
    query spending ($fromDate: String!, $toDate: String!, $accountId: String!, $accountWalletId: String) {
      spending (fromDate: $fromDate, toDate: $toDate, accountId: $accountId, accountWalletId: $accountWalletId) {
          amount
          currency
          mcg
          transactions {
              accountId
              accountWalletId
              approvedAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              authExpireOn
              authId
              availableBalance {
                  amount
                  currency
              }
              cardId
              cashbackAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              consumerId
              currentBalance {
                  amount
                  currency
              }
              description
              externalRefNo
              feeAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              id
              interfaceType
              isAdvice
              merchant {
                  city
                  country
                  group
                  name
                  postalCode
                  state
                  type
              }
              messageType
              originalTransactionId
              partialApproved
              paymentNetwork
              productCode
              requestType
              resultCode
              resultDetailCode
              retrievalReferenceNo
              surchargeAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              transactionAmount {
                  amount
                  currency
                  date
                  exchangeRate
              }
              transactionDatetime
              transactionStatus
              transactionType
          }
      }
  }
    `,
    {
      offset: pageNumber,
      limit: pageSize,
      ...(fromDate && { fromDate }),
      ...(toDate && { toDate }),
      ...(transactionStatus && { transactionStatus }),
      ...(accountId && { accountId }),
      ...(walletAccount && { accountWalletId: walletAccount }),
    }
  );
};

export const walletTransfer = (transferProps: any) => {
  const {
    amount,
    currency,
    fromWalletCurrency,
    toWalletCurrency,
    description,
  } = transferProps;
  return mutation(
    `mutation payerToPayerTransfer ($beneficiary: EntityInput!, $description: String, $externalRefNo: String, $payer: EntityInput!, $transferAmount: TransferAmountInput!, $transferType: String) {
        payerToPayerTransfer (beneficiary: $beneficiary, description: $description, externalRefNo: $externalRefNo, payer: $payer, transferAmount: $transferAmount, transferType: $transferType) {
            transfer {
                beneficiary {
                    id
                    name
                    type
                }
                description
                externalRefNo
                feeAmount {
                    amount
                    currency
                    exchangeRate
                }
                id
                payer {
                    id
                    name
                    type
                }
                status
                transferAmount {
                    amount
                    currency
                    exchangeRate
                }
                transferDateTime
                transferType
            }
        }
    }`,
    {
      description,
      transferAmount: {
        amount: amount,
        currency: currency,
      },
      payer: {
        id: fromWalletCurrency,
        type: "ACCOUNT_WALLET",
      },
      beneficiary: {
        id: toWalletCurrency,
        type: "ACCOUNT_WALLET",
      },
    }
  );
};

export const getRestrictions = (cardId: string) => {
  return query(
    `query restrictions ($cardId: String!) {
        restrictions (cardId: $cardId) {
            blockCashWithdrawal
            blockECommerce
            blockInternational
            filterId
            riskControlId
            riskControlRulesId
        }
    }`,
    { cardId }
  );
};

export const updateRestrictions = (restrictionProps: any) => {
  return mutation(
    `mutation updateRestriction ($blockCashWithdrawal: Boolean!, $blockECommerce: Boolean!, $blockInternational: Boolean!, $cardId: ID!, $filterId: ID!, $riskControlId: ID!, $riskControlRulesId: ID!) {
        updateRestriction (blockCashWithdrawal: $blockCashWithdrawal, blockECommerce: $blockECommerce, blockInternational: $blockInternational, cardId: $cardId, filterId: $filterId, riskControlId: $riskControlId, riskControlRulesId: $riskControlRulesId) {
            restriction {
                blockCashWithdrawal
                blockECommerce
                blockInternational
                filterId
                riskControlId
                riskControlRulesId
            }
        }
    }`,
    { ...restrictionProps }
  );
};
export const updatePin = (pinProps: any) => {
  return mutation(
    `mutation pinChange ($cardId: ID!, $newPin: String!) {
        pinChange (cardId: $cardId, newPin: $newPin) {
            success
            verified
        }
    }`,
    { ...pinProps }
  );
};

export const updateCardStatus = (statusProps: any) => {
  return mutation(
    `mutation updateCardStatus ($cardId: ID!, $status: String!) {
        updateCardStatus (cardId: $cardId, status: $status) {
            card {
                accountId
                activationDate
                cardExpiry
                consumerId
                creationDate
                fulfillment {
                    patternCode
                    shippingType
                }
                id
                lastFour
                paymentNetwork
                printedConsumerName
                productCode
                productName
                replacementFor
                replacementReason
                status
            }
        }
    }`,
    { ...statusProps }
  );
};

export const getProductWallets = () => {
  return query(`
    query productWallets {
        productWallets {
            code
            createdOn
            currencyCode
            name
        }
    }`);
};

export const getCardSecureData = (cardId: string) => {
  return query(
    `
  query cardSecureData($cardId: String!) {
    cardSecureData(cardId: $cardId) {
        cardId
        cvv2
        expiryDate
        pan
        printedConsumerName
    }
  }`,

    { cardId }
  );
};

export const fetchConsumerType = (email: string) => {
  return query(
    `
  query userType ($email: String!) {
    userType (email: $email)
}`,
    {
      email,
    }
  );
};

export const fetchBankAccountsStatement = (accountId: string) => {
  return query(
    `query accountStatements ($accountId: String!) {
    accountStatements (accountId: $accountId) {
        description
        dispatchStatus
        endDate
        id
        requestedOn
        startDate
    }
}`,
  {
    accountId,
  }
  )
}

export const downloadStatementFile = (documentId:string)=>{
  return query(
    `query statementDocumentLink ($documentId: String!) {
    statementDocumentLink (documentId: $documentId)
    }`,
    {
      documentId
    }
  )
}
export const fetchLookupData = (lookupProps: string[]) => {
  return query(
    `query lookupData ($lookupCodes: [String!]!) {
      lookupData (lookupCodes: $lookupCodes) {
        lookupCode
        lookupTable {
          code
          description
          name
          title
          visibleOrder
        }
      }
    }`,
    {
      lookupCodes: lookupProps,
    }
  );
};
export const getSubDivisionsLookup = (countryCode:string) => {
  return query(
    `query subDivisionsLookup ($countryCode: String!) {
      subDivisionsLookup (countryCode: $countryCode) {
        lookupCode
        lookupTable {
          code
          description
          isMandatory
          name
          title
          visibleOrder
        }
      }
    }`,
    { countryCode }
  );
};

export const deleteDepositDocument = (id:string) => {
  return query(
    `mutation deleteDocument($id: ID!) {
      deleteDocument(id: $id) {
        success
      }
    }`,
    { id }
  )
}

export const getDepositDocuments = (depositId:string) => {
  return query(
    `query documentLink($id: String!) {
      documentLink(id: $id) {
       contentType
        link
        name
      }
    }`,
    { id: depositId }
  )
}

export const createCreditTransferBatch = (props: MutationCreateCreditTransferBatchArgs) => {
  return mutation(
    ` mutation createCreditTransferBatch(
      $additionalData: JSON,
      $description: String,
      $externalRefNo: String,
      $requestType: String,
      $transaction: TransferTransactionDetailInput!
    ) {
      createCreditTransferBatch(
        additionalData: $additionalData,
        description: $description,
        externalRefNo: $externalRefNo,
        requestType: $requestType,
        transaction: $transaction
      ) {
        transferBatch {
          additionalData
          affiliateId
          confirmedBy
          confirmedOn
          createdBy
          createdOn
          description
          errorCount
          externalRefNo
          id
          note {
            agentCode
            agentName
            appCode
            channel
            createdOn
            entity {
              ddaNumber
              id
              name
              title
              type
              walletTitle
            }
            id
            text
            type
          }
          partnerId
          payer {
            ddaNumber
            id
            name
            title
            type
            walletTitle
          }
          pendingAmount
          pendingCount
          processedAmount
          processedCount
          productOwnerId
          requestType
          requestedAmount
          requestedAt
          requestedCount
          scheduledFor
          status
          transactionCurrency
          type
        }
      }
    }
    `,
    props
  );
};

export const sendEntityOtp = (channel: string, id: string, reasonType: string) => {
  return query(
    `mutation sendEntityOtp ($channel: String!, $id: String!, $reasonType: String!) {
      sendEntityOtp (channel: $channel, id: $id, reasonType: $reasonType) {
        success
      }
    }`,
    { channel, id, reasonType }
  );
};

export const verifyEntityOtp = (channel: string, id: string, otp: string) => {
  return query(
    `mutation verifyEntityOtp ($channel: String!, $id: String!, $otp: String!) {
      verifyEntityOtp (channel: $channel, id: $id, otp: $otp) {
        confirmationToken
      }
    }`,
    { channel, id, otp }
  );
};

export const resetPassword = (confirmationToken: string, email: string, password: string) => {
  return query(
    `mutation resetPassword ($confirmationToken: String!, $email: String!, $password: String!) {
      resetPassword (confirmationToken: $confirmationToken, email: $email, password: $password) {
        success
      }
    }`,
    { confirmationToken, email, password }
  );
};

export const inspectAuthEntity = (channel: string, id: string) => {
  return query(
    `query inspectAuthEntity ($channel: String!, $id: String!) {
      inspectAuthEntity (channel: $channel, id: $id)
    }`,
    { channel, id }
  );
};

export const validateAuthConfirmationToken = (eventType: string, confirmationToken: string) => {
  return query(
    `query validateAuthConfirmationToken ($eventType: String!, $confirmationToken: String!) {
      validateAuthConfirmationToken (eventType: $eventType, confirmationToken: $confirmationToken)
    }`,
    { eventType, confirmationToken }
  );
};



export const authLogin = (loginParam:MutationAuthLoginArgs) => {
  return loginQuery(
    `mutation authLogin (
      $deviceInfo: JSON!,
      $mfaToken: String,
      $otp: String,
      $password: String,
      $username: String
    ) {
      authLogin (
        deviceInfo: $deviceInfo,
        mfaToken: $mfaToken,
        otp: $otp,
        password: $password,
        username: $username
      ) {
        authToken {
          accessToken
          refreshToken
          passwordExpiryRemainingDays
        }
      }
    }`,
    loginParam
  );
};

export const refreshAuthToken = (refreshToken: string) => {
  return query(
    `mutation refreshAuthToken ($refreshToken: String!) {
      refreshAuthToken (refreshToken: $refreshToken) {
        authToken {
          accessToken
          refreshToken
          passwordExpiryRemainingDays
        }
      }
    }`,
    { refreshToken }
  );
};

export const createAuthConsumer = ({
  addresses,
  dateOfBirth,
  email,
  firstName,
  lastName,
  password,
  emailConfirmationToken,
  phoneDetails,
  kycData,
}:MutationCreateAuthConsumerArgs) => {
  return query(
    `mutation createAuthConsumer(
      $addresses: [AddressInput!], 
      $citizenshipCountryCode: String, 
      $dateOfBirth: String, 
      $email: String!, 
      $emailConfirmationToken: String!, 
      $enrollmentCode: Int, 
      $firstName: String!, 
      $gender: String, 
      $kycData: [KycDataInput!], 
      $lastName: String!, 
      $middleName: String, 
      $password: String!, 
      $phoneDetails: [PhoneDetailInput!]
    ) {
      createAuthConsumer(
        addresses: $addresses, 
        citizenshipCountryCode: $citizenshipCountryCode, 
        dateOfBirth: $dateOfBirth, 
        email: $email, 
        emailConfirmationToken: $emailConfirmationToken, 
        enrollmentCode: $enrollmentCode, 
        firstName: $firstName, 
        gender: $gender, 
        kycData: $kycData, 
        lastName: $lastName, 
        middleName: $middleName, 
        password: $password, 
        phoneDetails: $phoneDetails
      ) {
        profile {
          accounts {
            accountProxyNo
            accountWallets {
              accountId
              availableBalance
              chainingPriority
              createdOn
              currency
              currentBalance
              id
              includedInChaining
              isDefaultWallet
              productWalletCode
              status
              title
            }
            aggregatedAvailableBalance {
              amount
              currency
            }
            aggregatedCurrentBalance {
              amount
              currency
            }
            cards {
              accountId
              activationDate
              cardExpiry
              consumerId
              creationDate
              id
              lastFour
              paymentNetwork
              printedConsumerName
              productCode
              productName
              replacementFor
              replacementReason
              status
            }
            consumerId
            createdOn
            ddaNumber
            displayOrder
            id
            productCode
            productName
            routingNumber
            status
            title
            type
          }
          addresses {
            address1
            address2
            addressType
            city
            country
            postalCode
            state
          }
          cards {
            accountId
            activationDate
            cardExpiry
            consumerId
            creationDate
            fulfillment {
              patternCode
              shippingType
            }
            id
            lastFour
            paymentNetwork
            printedConsumerName
            productCode
            productName
            replacementFor
            replacementReason
            status
          }
          citizenshipCountryCode
          dateOfBirth
          email
          entityType
          externalRefNo
          firstName
          gender
          id
          kycData {
            expiryDate
            issuanceCountry
            issuanceDate
            issuanceId
            maskedIssuanceId
            type
          }
          kycStatus
          lastName
          phoneDetails {
            countryDialingCode
            phoneNumber
            type
          }
        }
      }
    }`,
    {
      addresses,
      dateOfBirth,
      email,
      firstName,
      lastName,
      password,
      emailConfirmationToken,
      phoneDetails,
      kycData,
    }
  );
};