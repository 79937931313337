const defaults = {
  ////// config /////////

  brandName: "Cloud Card",

  /// GENERIC LABELS ////
  yesLabel: "Yes",
  areYouSureTxt: "Are You Sure?",
  walletLabel: "Wallet",
  forgetLabel: "Forgot ?",
  cancelLabel: "Cancel",
  stayLabel: "Stay",
  logoutLabel: "Log Out",
  cardPlaceholder: "Yes Bank",
  ///// Login Screen /////

  purposeLabel: "Banking With a Purpose",
  purposeLabelColor: "#000000",
  getCartTitle: "Get a card today!",
  getCartDescription:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  getStartedButtonTitle: "Get Started",
  loginButtonTitle: "Login",
  loginDescription: "Dont have an Account ?",
  signUpButtonTitle: "Sign Up",
  forgotButtonTitle: "Forgot Password",
  loginEmailLabel: "Email",
  loginPasswordLabel: "Password",
  cancelBtnLabel: "Cancel",
  nextBtnLabel: "Next",
  cardOperationBtnLabel: "Card Operation",
  loginLabel: "Login",
  signInLabel: "Sign In",
  transferHistoryButtonTitle: "Transfer History",
  cancelConfimationTxt: "You want to cancel this, it will delete everything you have filled",
  cancelConfirmLoginTxt: "Are you sure you want to cancel login screen.",
  resetPasswordConfirmTxt: "Are you sure you want to cancel reset password flow.",
  loginleftInnerFirstCol: "true",
  loginBottomFooter: "true",
  loginLeftColFooter: "true",
  loginNavLinkColor: "#006cf8",
  showBeneficiaryTransferOption: "Y",
  showMyAccountsTransferOption: "Y",
  showBetweenAccountsTransferOption: "Y",
  showDepositTransferOption: "Y",
  userNameLabel: "Username",
  passwordLabel: "Password",
  resetPasswordLabel: "Reset Password",
  continueLabel: "Continue",
  personalLabel: "Personal",
  businessLabel: "Business",
  consumerLoginProfileType: ["CONSUMER", "AFFILIATE"],



  // PDF Links //

  privacyPolicyLink:
    "https://cc-widgets-assets.s3.amazonaws.com/Privacy+Policy.pdf",
  termsAndConditionsLink:
    "https://cc-widgets-assets.s3.amazonaws.com/Terms+and+Conditions.pdf",

  // ForgetPassword
  passwordRest: "Password Reset",
  provideEmailVerification: "Please Provide your Email ID for verification",
  emailRequiredMsg: "Please Enter your Email",
  newPasswordSubtitleText: "Set your new password.",
  confirmPasswordMsg: "Please confirm your password",
  passwordDoNotMatch: "The two passwords that you entered do not match",
  optPasscodeContent:
    "An OTP (one time passcode) has been sent to your email. Please enter it to verify.",
  bankingPurposeLabel: "Banking With a Purpose",

  //Header//

  helloUserText: "Welcome,",
  helloUserTextColor: "#420D3F",
  addAccountLabel: "Add Account",

  ///// dashboard Screen /////
  homeMenuTitle: "Home",
  transferMenuTitle: "Transfers",
  MenuTitle: "Transfers",
  accountStatementMenuTitle: "Account Statements",
  programNametitle: "Program Name",
  accountNametitle: "Account Name",
  accountNumbertitle: "Account Number",
  routingNumbertitle: "Routing Number",
  TotalInTitle: "Total in",
  subAccountInTitle: "Sub Accounts",
  addNewButtonTitle: "Add New",
  transactionTitle: "Transactions",
  accountTitle: "Account Title",
  balanceTitle: "Balance",
  creditTitle: "Credit",
  debitTitle: "Debit",
  totalBalanceInUsdTitle: "Total Balance in USD",
  lastCreditTitle: "Last Credit",
  lastDebitTitle: "Last Debit",
  addSubAccountTitle: "Add Sub Account",
  subAccountTitlelabel: "Sub Account Title",
  editSubAccountTitle: "Edit Sub Account",
  availableBalanceTitle: "Available Balance",
  totalBlanceTitle: "Total Balance",
  currentBalanceTitle: "Current Balance",
  weAreLaunchingLabel: "We are launching",
  launchingDebitLabel: "Consumer Debit",
  masterCardSoonLabel: "Mastercard Soon",
  confirmCurrencyTxt: "You want to add",
  noDataAvailabel: "No Data Available",
  walletsLabel: "Wallets",
  availableLabel: "Available",
  currentLabel: "Current",
  cardSecureDetailsTitle: "Card Secure Details",
  addAccountTitle: "Add Account",
  addAccountDescription: "Enter Account Details",
  showCardBtnLabel: "Show Card",
  hideCardBtnLabel: "Hide Card",
  enableAddAccount: "Y",
  enableAddWallet: "Y",
  showRoutingNumber: "Y",
  ////////// Bank transfer ////////

  bankTransferTitle: "Bank Transfer",
  bankTransferDescription:
    "Send money to a person or business using email or phone no.",
  addBankTransferButtonTilte: "Bank Account",
  bankTableTitle: "Bank",
  NickNameTableTitle: "Name",
  accountTableTitle: "Account",
  statusTableTitle: "Status",
  addBankAccountTitle: "Add Bank Account",
  legalAccountNameLabel: "Legal Account Name",
  bankNameLabel: "Bank Name",
  accountNumberLabel: "Account Number",
  accountTypeLabel: "Account Type",
  routingNumberLabel: "Routing Number",
  productNameLabel: "Product",
  nickNameLabel: "Nick Name",
  verificationRequiredTitle: "Verification required",
  privacyPolicyCheckBoxText:
    "I agree to the Terms and Conditions or I agree to the Privacy Policy",

  ///// transfer screeen /////

  accountSummary: "Account Summary",
  transactionHistoryLabel: "Transaction History",
  transferTitle: "Between Account",
  transferConfirmationTitle: "Review and Confirm",
  transferDescription: "Transfer funds to a beneficiary",
  betweenAccountsTagLine: "Transfer funds between your Consumer Accounts",
  transferAmountTitle: "Amount",
  transferFromTitle: "From",
  transferToTitle: "To",
  transferMainAccountTitle: "Account",
  transferSubAccountTitle: "Sub Account",
  transferWalletAccountTitle: "Wallet",
  transferTransferDateTitle: "Transfer date",
  recentTransfersTitle: "Recent Transfers",
  fnfMenuTitle: "My Beneficiaries",
  friendFamilyDescription: "Transfer funds to your beneficiaries",
  operationMenuTitle: "Card Operations",
  friendTitle: "Beneficiary",
  historyTitle: "History",
  nameTitle: "Name",
  emailTitle: "Email",
  phoneTitle: "Phone",
  transferFundsButtonTitle: "Transfer",
  addRecipientTitle: "Add Beneficiary",
  addRecipientDescription:
    "Search a user using their Account Number OR registered mobile phone",
  searchRecipientButtonTitle: "Search Beneficiary",
  recipientListTitle: "Recipient list",
  enterOTPTitle: "Enter OTP",
  enterOTPDescrip: "Please enter the 4 digit OTP sent to your registered email",
  resendOTP: "Resend OTP",
  resendText: "Resend Code",
  congratulationsTitle: "Congratulations!",
  congratulationsDescription: "Recipient is successfuly added",
  bankVerifyTitle: "Enter the deposit amounts made to your bank account",
  bankVerifydescription:
    "Don’t see them yet? the depositis make take 1 to 2 business days to show on your bank statement.",
  enterNickNameTitle: "Enter Nickname",
  enterNickNamedespcri: "Please enter beneficiary's nickname",
  addBeneficiaryLabel: "Add Beneficiary",
  transferFundsTitle: "Transfer Funds",
  transferFundChooseOptionTxt: "Choose one of the option below to start.",
  transferFundOptionOneTitle: "To a Beneficiary",
  transferFundOptionOneContent:
    "Send Money to a Person Or Business to their Consumer Account",
  transferFundOptionTwoTitle: "My Bank Accounts",
  transferFundOptionTwoContent: "Transfer To & From an external Bank Account",
  transferFundOptionThreeTitle: "Between My Accounts",
  transferFundOptionThreeContent: "Transfer funds between your Consumer Accounts",
  transferFundOptionFourTitle: "Deposit",
  transferFundOptionFourContent: "Securely save funds for future use or transactions.",
  successfulRequest: "Thank you. Your request was successful",
  sentMoneyDescription:
    "Your money has been sent and will be credited to the beneficiary’s account immediately, subject to our standard checks.",
  makeAnotherTransferLabel: "Make Another Transfer",
  noThanksLabel: "No Thanks I am Done",
  exchangeRateLabel: "Exchange Rate ",
  noWalletExist: "No Other Wallet Exists",
  exhcangeRateDefaultLabel: "Exchange Rate",
  defaultZeroValueText: "0.00",
  reviewTransferSummaryText: "Review Transfer Summary",
  toABeneficiaryTitle: "To A Beneficiary",

  // Account Statement
  noFileDownloadStatementError: 'No statement found to download',

  // Labels
  makeAnotherLabel: "Make Another",
  remainingAmountLabel: "Remaining Amount",
  doneLabel: "Done",
  successfulTransferFundText: "Your funds have been successfully transferred",
  applyFilterText: "Apply",
  addSubAccountSuccessMessage: "Sub account has been added successfully",
  addWalletSuuccessMessage: "Wallet has been added successfully",
  updateSubAccountSuccessMessage: "Sub account has been update successfully",
  fundsTransferSuccessMessage: "Funds have been transferred successfully",
  BankAccountSuccessMessage: "Bank Account have been Created successfully",
  youAreAllSet: "You’re All Set",

  noStatementLable:"No statement is available",

  // Card operations

  changePinLabel: "Change PIN",
  changePinContent:
    "Instantly change the PIN of your card. This PIN will be used for ATM cash ƒwithdrawals and POS PIN transactions",
  // Footers

  privacyLinkLabel: "Privacy Policy",
  termsAndConditionsLinkLabel: "Terms & Conditions",
  copyRightsText: "All Rights Reserved.",
  poweredByContent: "Powered by CloudCard Inc.",
  footerBrandText:"A Wholly-Owned Subsidiary of MBS Global Holdings",

  //Needs to be added in errors
  TA001: "Declined - Insufficient funds",
  SV001: "Invalid beneficiary",
  AU015: "Invalid Request: Provided Email has already been used.",
  AU022: "Invalid OTP: Retry Limit Exceeded",
  AU011: "Invalid OTP",
  Min_SSN_Length: "Minimum 9 digits!",

  GENERAL: "Something went wrong",
  REQUIRED: "Required!",
  INVALID_EMAIL: "Invalid Email!",
  EMPTY_EMAIL: "Please enter email",
  INVALID_PHONE: "Invalid Phone Number!",
  WEAK_PASSWORD:
    "Must contain uppercase, lowercase, digit and special character!",
  MISMATCH_PASSWORD: "Password do not match!",
  SHORT_PASSWORD: "Minimum 8 characters!",
  INVALID_SSN: "Invalid SSN!",
  INVALID_CITY: "Invalid City!",
  INVALID_POSTAL: "Invalid zip code!", //needs to be changed in db
  SHORT_POSTAL: "Minimum length should be 5",
  EMAIL_ALREADY_EXISTS: "Email already exists!",
  INVALID_CODE_LENGTH: "Code length is 6",

  INVALID_INVITATION_EMAIL: "Could not find any invitation for this email!",
  USED_SUPPLEMENTARY_EMAIL: "Invitation already used!",

  CARD_NOT_DELIVERED: "Physical card has not been dispatched yet!",
  MAX_AGE_LIMIT: "Maximum age limit is 30 years old",
  MIN_AGE_LIMIT: "Minimum age limit is 18 years old",

  MAX_AMOUNT_LIMIT: "Amount can not be greater than available balance",
  MIN_AMOUNT_LIMIT: "Amount must be greater than 0",
  INVALID_NUMBER: "Invalid Number",
  INVALID_DECIMAL: "Invalid decimal points",

  INVALID_PIN_LENGTH: "Invalid pin length",
  INVALID_PIN_MATCH: "Pin doesn’t match",
  SELECT_CARD: "Please select card",
  INVALID_USERNAME_PASSWORD: "Invalid username or password",

  networkLogos:
    '[{"code":"CLOUDCARD", "logo":"cloudcard_white_logo"},{"code":"VISA", "logo":"visa_logo"},{"code":"MASTER", "logo":"master_logo"}]',

  processedStatusColor: "#00CD9C",
  pendingStatusColor: "#F79E1B",
  darkBlueColor: "#2C3E50",
  bluePinChangeColor: "#006CF8",
  // primaryColor: "#7C5838",
  primaryColor: "",
  blackColor: "#000000",
  blueColor:
    "linear-gradient(139deg, rgb(6, 71, 166) 0%, rgb(68, 44, 150) 100%)",
  defaultGreenColor: "#27AD60",
  successfulGreenColor: "#1ABC9C",
  verifiyGreenColor: "#27AE60",
  verifyBtnGreenColor: "#2ECC71",
  formFieldsPrimaryColor: "",
  formFieldsBorderColor: "#00000040",
  btnBgPrimaryColor: "#006CF8",
  btnBgSecondaryColor: "#006CF8",
  nameInitialsTextColor: "#006CF8",
  nameInitialsBgColor: "#006CF81A",
  addRecipientIconColor: "#006CF8",
  filterDrawerBackgroundColor: "#006CF8",
  addBeneficiaryDrawerBgColor: "#006CF8",
  themePrimaryColor: "#006CF8",
  themeSecondaryColor: "#006CF8",
  linkColor: "#006CF8",

  statusRedColor: "#E76255",
  statusYellowColor: "#DBB131",

  titleColor: "#05315F",
  subTitleColor: "#A7A7A7",
  labelColor: "#34495E",
  tagColor: "#747F8B",
  placeholderColor: "#34495E",
  headerGreyColor: "#66788A",
  greyColor: "#7F8C8D",
  textColor: "#34495E",
  borderColor: "#A5A5A5",
  resendColor: "rgba(124, 88, 56)",
  timeColor: "rgba(124, 88, 56)",
  fontColor: "#0F3A5E",
  orangeColor: "#E76F6F",
  orangeButtonColor: "#FFA500",
  pendingOrangeColor: "#F5A623",
  greenColor: "#18BC9B",
  yellowColor: "#FBEC5D",
  redColor: "#D0312D",
  cardNetworkTextColor: "#ffffff",
  backgroundTransparentColor: "transparent",

  dashboardContentColor: "#34495E",

  reviewHeaderColor: "#F2F2F2",

  primaryButtonLabelColor: "#fff",
  primaryButtonBackground: "#006CF8",
  primaryButtonBorder: "#006CF8",

  secondaryButtonLabelColor: "#006CF8",
  secondaryButtonBackground: "#fff",
  secondaryButtonBorder: "#006CF8",

  sidebarBackground: "linear-gradient(#092338, #0F395C)",
  topBarTitleColor: "#0a2942",
  activeNavBackground: "#103A5E",
  avatarBackgroundColor: "#EFEFEF",
  avatarFontColor: "#87939D",

  activeBackground: "#2ECD72",
  inactiveBackground: "#E11022",

  horizontalLineColor: "#D7D7D7",

  boxBackgroundColor: "#EAEBED",

  previewTextColor: "#4a5465",

  listBorderColor: "#BDC3C7",

  grayColor: "#d8d8d8",
  grayBorderColor: "#9CA3AC",

  lightBlueColor: "#047EFD",
  pendingBlueColor: "#3498DB",

  signUpTitleColor: "#2C3E50",
  signUpSubTitleColor: "#76828E",

  signupPrimaryButtonLabelColor: "#fff",
  signupPrimaryButtonBackground: "#006CF8",
  signupPrimaryButtonBorder: "#006CF8",

  signupSecondaryButtonLabelColor: "#006CF8",
  signupSecondaryButtonBackground: "#fff",
  signupSecondaryButtonBorder: "#006CF8",

  // sign up success
  signupKycPassedSuccessMessage: "Thank you for choosing Consumer app welcome aboard",
  signupKycPendingReviewMessage:
    "Thank you for choosing Consumer app. Your application is under review and you will be contacted soon.",
  signupSuccessBgImage: "false",

  // configs
  accountPrograms: '["EVO_GPR"]',
  defaultMainView: "login",
  countries:
    '[ { "name": "Canada", "code": "CAN" }, { "name": "India", "code": "IND" }, { "name": "Malaysia", "code": "MYS" }, { "name": "Pakistan", "code": "PAK" }, { "name": "Qatar", "code": "QAT" }, { "name": "Saudi Arabia", "code": "SAU" }, { "name": "United Arab Emirates", "code": "ARE" }, { "name": "United Kingdom", "code": "GBR" }, { "name": "United States of America", "code": "USA" } ]',
  states:
    '[ { "name": "Alabama", "code": "AL" }, { "name": "Alaska", "code": "AK" }, { "name": "Arizona", "code": "AZ" }, { "name": "Arkansas", "code": "AR" }, { "name": "California", "code": "CA" }, { "name": "Colorado", "code": "CO" }, { "name": "Connecticut", "code": "CT" }, { "name": "Delaware", "code": "DE" }, { "name": "Florida", "code": "FL" }, { "name": "Georgia", "code": "GA" }, { "name": "Hawaii", "code": "HI" }, { "name": "Idaho", "code": "ID" }, { "name": "Illinois", "code": "IL" }, { "name": "Indiana", "code": "IN" }, { "name": "Iowa", "code": "IA" }, { "name": "Kansas", "code": "KS" }, { "name": "Kentucky", "code": "KY" }, { "name": "Louisiana", "code": "LA" }, { "name": "Maine", "code": "ME" }, { "name": "Maryland", "code": "MD" }, { "name": "Massachusetts", "code": "MA" }, { "name": "Michigan", "code": "MI" }, { "name": "Minnesota", "code": "MN" }, { "name": "Mississippi", "code": "MS" }, { "name": "Missouri", "code": "MO" }, { "name": "Montana", "code": "MT" }, { "name": "Nebraska", "code": "NE" }, { "name": "Nevada", "code": "NV" }, { "name": "New Hampshire", "code": "NH" }, { "name": "New Jersey", "code": "NJ" }, { "name": "New Mexico", "code": "NM" }, { "name": "New York", "code": "NY" }, { "name": "North Carolina", "code": "NC" }, { "name": "North Dakota", "code": "ND" }, { "name": "Ohio", "code": "OH" }, { "name": "Oklahoma", "code": "OK" }, { "name": "Oregon", "code": "OR" }, { "name": "Pennsylvania", "code": "PA" }, { "name": "Rhode Island", "code": "RI" }, { "name": "South Carolina", "code": "SC" }, { "name": "South Dakota", "code": "SD" }, { "name": "Tennessee", "code": "TN" }, { "name": "Texas", "code": "TX" }, { "name": "Utah", "code": "UT" }, { "name": "Vermont", "code": "VT" }, { "name": "Virginia", "code": "VA" }, { "name": "Washington", "code": "WA" }, { "name": "West Virginia", "code": "WV" }, { "name": "Wisconsin", "code": "WI" }, { "name": "Wyoming", "code": "WY" } ]',

  dateRangeFormat: "YYYY-MM-DD",

  copyrightText: "2022 © Copyright Open CP. All Rights Reserved",

  // Brand Images

  loginBrandImgSrc: "default-login-mobile-img.png",
  appleStoreBadge: "app-store-button.png",
  androidStoreBadge: "play-store-button.png",
  brandLogo: "default-cc-logo.png",
  brandSecondaryLogo: "default-cc-secondary-logo.png",
  passwordShowImg: "default-password-show-icon.png",
  passwordHideImg: "default-password-hide-icon.png",
  authLoginBannerBackgroundImgSrc: "",
  authBannerBackgroundImgSrc: "",
  consumerBrandBackgroundColor: "",
  successImageSrc: "default-cc-success-image.png",
  successTopBackgroundImage: "",
  successBottomBackgroundImage: "",
  dropdownArrowIcon: "dropdown-arrow.png",
  settingsIcon: "settings-icon.png",
  cardChip: "cloudcard-vc-chip.png",
  cardSignalIcon: "card-vc-signal-white.png",
  masterCardLogo: "master_logo.png",
  backOfCard: "boc_blue.png",
  cardNetworkBackgroundImage: "",
  debitPaymentIcon: "default-cc-payment-icon.png",
  eyeIcon: "show-card-eye-icon.png",
  transferHistoryIcon: "transfer-history.png",
  addBeneficiaryIcon: "add-beneficiary.png",
  deleteItemIcon: "delete-item-icon.png",
  beneficiaryTransferIcon: "beneficiary-transfer-icon.png",


  // Buttons
  buttonNotFound: "Button type does not exist",
  confirmButtonText: "Yes I am sure",
  confirmLabel: "Yes",
  viewAllLabel: "View All",
  deleteRecipientConfirmBtnLabel: "Yes, I am sure",
  deleteRecipientConfirmText: "You want to delete this recipient.",

  // Session Expiry 

  sessionExpiryTitle: "Session Expiry Alert!",
  sessionExpiryDescription: "Your session is about to expire due to inactivity. You will be logged out in",


  // Forms
  emailFormTitleLabel: "Lets Begin!",
  codeVerificationlFormTitleLabel: "One Time Passcode",
  personalInfoFormTitleLabel: "Personal Information",
  createAccountFormTitleLabel: "Create Your Account",
  identityVerificationLabel: "Verify Your ID",
  addressFormLabel: "Physical Card Delivery",
  reviewDetailsLabel: "Review Your Details",
  cardholderAgreementLabel: "Cardholder Agreement",
  oneTimePasscodeLabel: "One Time Passcode",
  passwordEmailFormLabel: "Password Reset",
  convertedTextLabel: "Converted",


  /**Consumer Setup */
  setYourPasswordLabel: "Set your password",
  passwordPatternContentPartOne:
    "Password should be at least 8 characters long.",
  passwordPatternContentPartTwo:
    "Password must contain an uppercase letter, a lowercase letter, a number and a special character.",
  consumerSuccessText: "Please click here to login",
  passwordSetupLoginLabel: "Login",
  passwordSuccesfullLabel: "Password Setup Successfull",
  invitationLinkExpiry: 'Invitation link is expired.',


  // Form Verify Identity
  verifyParagraphOne: "To help Consumer comply with the local and international laws and regulations, we need you to verify your ID.",
  verifyParagraphTwo: "<span>Verification only takes a few minutes</span>, helps secure your account & payments.",
  verifyParagraphThree: "<span>Have your ID</span> handy and you'll be taken through ID verification process.",
  verifyIdLabel: "Verify Your ID",

  // FORM Address
  defaultAddressCountry: "ARE",

  // Signup congratulations
  youAreAllSetLabel: "You're All Set!",

  /**** DASHBOARD ****/

  sideBarCollapsedLogo: "default-cc-collapsed-logo.png",
  sideBarExpandedLogo: "default-cc-white-logo.png",
  navDashboardIcon: "dashboard.png",
  navWalletTransferIcon: "wallet-transfer.png",
  navAccountStatementIcon:"account-statement.svg",
  sidebarBgColor: "#000000",
  dashboardActiveNavColor: "#006CF8",
  isNetworkCardBgImg: "false",
  isDefaultNetworkCard: "true",
  debitIconColor: "#006CF8",
  creditIconColor: "#AC56A7",


  // MetaDataDefaults

  showEyeIcon: "default-show-eye-icon.png",
  hideEyeIcon: "default-hide-eye-icon.png",
  transactionHistoryIcon: "transaction-history-icon.png",
  transactionIconColor: "#000000",
  transactionCardIconColor: "#000000",
  walletIcon: "wallet-icon.png",
  actionDragIcon: "drag-icon.png",
  collapsibleIcon: "collapsible-icon.png",
  collapsibleInactiveIcon: "collapsible-inactive-icon.png",
  collapsibleActiveIcon: "expand-open-panel-icon.png",
  dashboardTopBackgroundImage: "",
  dashboardBottomBackgroundImage: "",
  transferBeneficiaryImage: "default-transfer-beneficiary.png",
  transferBankAccountsImage: "default-transfer-bank-accounts.png",
  transferBetweenAccountsImage: "default-between-accounts.png",
  userProfileIcon: "default-user-profile-icon.png",
  brandFavicon: "cloudcard-favicon.png",
  transferTilesIconBackgroundColor: "#006CF8",
  activeNavBarColor: "#006CF8",
  noRateAvailableText: "No Rate Available",
  successTransferBackgroundColor: "#fff",
  successTransferImage: "transfer-success-image.png",
  loginFooterBackgroundColor: "",

  /* 
   * BreadCrumbs Labels
  */

  dashboardCrumbTitle: "Accounts Summary",
  walletTransfersCrumbTitle: "Transfers",
  beneficiaryCrumbTitle: "Beneficiary",
  beneficiariesCrumbTitle: "Beneficiaries",
  beneficiaryTransferFundsCrumbTitle: "To A Beneficiary",
  betweenAccountCrumbTitle: "Between Accounts",
  cardOperationsCrumbTitle: "Card Operations",
  bankTransferCrumbTitle: "Transfer Funds",
  transactionsCrumbTitle: "Transactions",
  transferHistoryCrumbTitle: "Transfer History",
  transfersCrumbTitle: "Transfers",
  accountStatementCrumbTitle: "Account Statements",
  depositCrumbTitle: "Deposit",


  /**
   * Product Codes
   */

  accountProductCode: "EVO_GPR",


  /**
   * Card Operations
   */


  freezeCardDescription: "Block/Un-block card at your own will without calling any customer services or IVR.",
  changePinDescription: "Instantly change the PIN of your card. This PIN will be used for ATM cash withdrawals and POS PIN transactions",

  /**
   * Buttons
   */

  darkBtnColor: "#000000",
  activeFilledBtn: "false",
  loginActiveFilledBtn: "false",
  cardFreezedMessage: "Card has been blocked",
  cardUnFreezedMessage: "Card has been activated",

  /**
  * Deposit Screen
  **/

  accountFormTitle: "Select Account",
  senderFormTitle: "Sender Information",
  documentsUploadTitle: "Documents Upload",
  depositReviewTitle: "Review Deposit",

  // upload documents variables
  documentUploadHeaderNameLabel: "Name / Description",
  documentUploadHeaderAttachmentLabel: "Attachment(s)",
  documentUploadHeaderFileSizeLabel: "Max file size 5MB",
  maxUploadSizeInMB: "5",

  documentUploadBtnLable: "Upload",
  documentInvalidFileFormatMsg: "Invalid file type. Only PNG, JPG, JPEG, and PDF are allowed.",
  documentInvalidFileSizeMsg: "File is too large. Maximum size is 5MB",
  documentUploadSuccessMsg: "Document uploaded successfully",
  depositAreYouSureTxt: "Are You Sure?",
  depositConfimationTxt: "You want to Deposit",

  successDepositBackgroundColor: "#fff",
  depositSuccessTitle: "Thank you for submitting the deposit request",
  depositSuccessButtonLabel: "Done",

  depositSubmitBtnLabel: "Submit",
  depositContinueBtnLabel: "Continue",
  depositCancelBtnLabel: "Cancel",

  footerLineTwo: "",
  footerLineTwoLinkText: "",
  isShowFooterLineTwoLink: "N",
  footerLineTwoLinkUrl: "",

  // Deposit Form
  accountLabel: "Account",
  amountLabel: "Enter Amount",
  purposeOfTransferLabel: "Purpose of Transfer",
  externalRefLabel: "External Reference",
  fullNameLabel: "Full Name",
  senderBankLabel: "Sender Bank",
  addressLabel: "Address",
  cityLabel: "City",
  stateLabel: "State",
  zipLabel: "Zip Code",
  countryLabel: "Country",
  descriptionLabel: "Description",

  rightSidebarBgColor:"linear-gradient(170.39deg, #27071C 0.52%, #4D3047 99.13%)",
  sidebarBottomBgImg:'sidebar-bottom.png',
  sidebarTopBgImgSrc: "sidebar-top.png",

} as any;
export default defaults;
