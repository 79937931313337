import { B2C_URL } from "../../credentials";
import axiosAPI from "../../utils/axios";

export const getAuthTokenAPI = async (surl: any) => {
  const b2cURL = B2C_URL();
  const baseUrl = b2cURL.split('graphql')[0] 
  const data = await axiosAPI.get(`${baseUrl?.trim()}${surl}`);
  const auth_Token = data.data ? JSON.stringify(data.data.data) : "{}";
  localStorage.setItem("Cloud_SecureURL", auth_Token);
  return auth_Token;
};

