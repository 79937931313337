import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  authTokenAvailable,
  callBackUrlAvailable,
  consumerIdAvailable,
  getAccessToken,
  parseURL,
  redirectLogInPage,
} from "../utils/modular-function";
import { prepareReturnResponce } from "../app-store/reducers/user/user-action";
import { setLoginUser } from "../app-store/reducers/user/user-reducer";
import { showErrorNotification } from "../utils/common-utils";
import { authTokenAPIredirection, authTokenWithLogin } from "./bootstrap-utils";
import { AuthAPIProps, CallBackUrlAvailableProps } from "./bootstrap-types";
import { authLogin } from "apis/b2c_api";
import { getDeviceUniqueId } from "containers/Authentication/authentication-utils";

const AuthAPIs = ({ setLoading }: AuthAPIProps) => {
  const navigate = useNavigate();
  const authToken = authTokenAvailable();
  const callBackUrl: CallBackUrlAvailableProps = callBackUrlAvailable();
  const consumerId = consumerIdAvailable();

  const dispatch = useDispatch();
  const deviceUniqueId = getDeviceUniqueId();

  useEffect(() => {
    if (authToken) {
      loginWithAuthToken(consumerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, consumerId]);

  useEffect(() => {
    const authTokenCallBackUrl = redirectLogInPage();
    const accessTokenInLogInAPI = getAccessToken();

    if (authTokenCallBackUrl || accessTokenInLogInAPI) {
      const { redirectPath } = authTokenAPIredirection(
        authTokenCallBackUrl,
        accessTokenInLogInAPI,
        consumerId
      );
      navigate(redirectPath);
    }
  }, [consumerId, navigate]);

  const loginWithAuthToken = async (consumerId: string) => {
    const loginParams = {
      authToken: authToken,
      deviceInfo: {
        device_unique_id: deviceUniqueId.device_info.device_unique_id,
      },
    };
    authLogin(loginParams)
      .then((response) => {
        const preparedResp = prepareReturnResponce(
          response.authLogin.authToken
        );
        dispatch(setLoginUser(preparedResp));
        const { redirectPath } = authTokenWithLogin(consumerId);
        navigate(redirectPath);
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error);
        if (error) {
          if (callBackUrl === false) {
            navigate("/login");
          }
          if (typeof callBackUrl === "string") {
            window.location.href = parseURL(callBackUrl);
          }
        }
      });
  };

  return <></>;
};

export default AuthAPIs;
