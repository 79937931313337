import { v4 as uuidv4 } from "uuid";


export const getDeviceUniqueId = () => {
    const deviceUniqueId = uuidv4();

    return {
        device_info: {
            device_unique_id: deviceUniqueId,
        },
    }
}